import React from 'react';
import styled from 'styled-components';
import Typography from '@ntuctech/devex-tangram/Typography';
import Grid from '@ntuctech/devex-tangram/Grid';
import { Link } from '@ntuctech/devex-tangram';
import ButtonOutlined from '@ntuctech/devex-tangram/Button/ButtonOutlined';
import { SCREEN_SIZE } from '../../lib/mediaQuery';
import B2BNeedHelp from '../icons/svg/B2BNeedHelp.svg';
import Bulk from '../icons/svg/Bulk.png';
import { B2B } from '../../constants';

const MainContainer = styled.div`
  background: #ffffff;
`;

const GridStyle = styled(Grid)`
  margin: 1.5rem 0;
  padding: 0;
`;

const Container = styled.div`
  display: flex;
  max-width: 75rem;
  flex-direction: column;
  align-items: center;
  margin: 0px auto;
  padding: 4rem 3rem;

  .contactUs {
    margin: 0;
    background-color: #ffffff;
    width: 14.125rem;
  }

  ${SCREEN_SIZE.Below.Tablet} {
    margin-bottom: 0;
    padding: 2rem 1rem;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    margin-bottom: 0;
    .contactUs {
      width: 100%;
    }
  }
`;
const StepIcon = styled.img`
  max-width: 24rem;
  width: 100%;
  height: auto;
  align-self: ${(p) => (p.isB2b ? 'flex-end' : 'start')};
  ${SCREEN_SIZE.Below.MobileL} {
    max-width: 100%;
  }
`;

const StepInfo = styled.div`
  text-align: ${(p) => (p.isB2b ? 'left' : 'right')};

  h2 {
    margin-bottom: 0.75rem;
  }
  .contact-us {
    margin-bottom: 1.5rem;
  }

  ${SCREEN_SIZE.Below.MobileL} {
    text-align: left;
    width: 100%;
    margin: 1.5rem 0 0;
  }
`;
const GridItem = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 0 2rem;

  ${SCREEN_SIZE.Below.Tablet} {
    padding: 0 1rem;
  }

  ${SCREEN_SIZE.Below.MobileL} {
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const contactUsURL =
  typeof window !== 'undefined' &&
  !window.location.origin.includes('preprod') &&
  !window.location.origin.includes('localhost')
    ? 'https://forms.gle/TEsH1ZUmw1ht8Lt19'
    : 'https://forms.gle/P48bb1hhX2VBFew97';

const NeedHelp = ({ isB2c }) => {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth <= 767);
    });

    return () => window.removeEventListener('resize', () => {});
  }, []);

  return (
    <>
      <MainContainer>
        <Container>
          <Grid container compact>
            {isB2c ? (
              <>
                {isMobile && (
                  <GridItem item s={6} xs={12}>
                    <StepIcon src={Bulk.src} />
                  </GridItem>
                )}
                <GridItem item s={6} xs={12}>
                  <StepInfo>
                    <Typography variant="titleXl">Corporate gifting made easy</Typography>
                    <Typography variant="bodyM">
                      Order in bulk for your event and receive it via email instantly. Easily send
                      whenever you like.
                    </Typography>
                    <GridStyle className="nos-spacing">
                      <Link size="l" href={`/?type=${B2B}`}>
                        <ButtonOutlined size="m" className="contactUs" data-testid="contactUs">
                          Find out more
                        </ButtonOutlined>
                      </Link>
                    </GridStyle>
                  </StepInfo>
                </GridItem>
                {!isMobile && (
                  <GridItem item s={6} xs={12}>
                    <StepIcon src={Bulk.src} />
                  </GridItem>
                )}
              </>
            ) : (
              <>
                <GridItem item s={6} xs={12}>
                  <StepIcon isB2b src={B2BNeedHelp.src} />
                </GridItem>
                <GridItem item s={6} xs={12}>
                  <StepInfo isB2b>
                    <Typography variant="h2">Need help?</Typography>
                    <Typography variant="body2" className="contact-us">
                      Let us know if you have any questions or difficulties with your order.
                    </Typography>
                    <Link href={contactUsURL} target="_blank">
                      <ButtonOutlined size="m" className="contactUs" data-testid="contactUs">
                        Contact us
                      </ButtonOutlined>
                    </Link>
                  </StepInfo>
                </GridItem>
              </>
            )}
          </Grid>
        </Container>
      </MainContainer>
    </>
  );
};

export default NeedHelp;
