import React from 'react';
import styled from 'styled-components';
import Typography from '@ntuctech/devex-tangram/Typography';
import ButtonContained from '@ntuctech/devex-tangram/Button/ButtonContained';
import { SCREEN_SIZE } from '../../lib/mediaQuery';

const MainContainer = styled.div`
  background: #0b2e66;
  color: #ffffff;
`;

const Container = styled.div`
  max-width: 75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px auto;
  padding: 4rem 3rem;

  .order-now-button {
    margin: 0;
    margin-bottom: 1.5rem;
    width: 10.8125rem;
  }

  ${SCREEN_SIZE.Below.Tablet} {
    margin-bottom: 0;
    padding: 3rem 2rem;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    margin-bottom: 0;
    padding: 2rem 1rem;
    .order-now-button {
      width: 100%;
    }
  }
`;

const Heading = styled(Typography).attrs({ variant: 'titleM' })`
  text-align: center;
  margin-bottom: 1.5rem;
  color: #ffffff;
`;

const B2BAppreciation = ({ handleB2BAction }) => {
  return (
    <>
      <MainContainer>
        <Container>
          <Heading>Show your appreciation today with a FairPrice E-Gift Voucher</Heading>
          <ButtonContained
            size="m"
            onClick={handleB2BAction}
            className="order-now-button"
            data-testid="banner-send-now"
            darkMode
          >
            Order now
          </ButtonContained>
        </Container>
      </MainContainer>
    </>
  );
};

export default B2BAppreciation;
