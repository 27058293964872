import React from 'react';
import styled from 'styled-components';
import { Typography, Link } from '@ntuctech/devex-tangram';
import ButtonContained from '@ntuctech/devex-tangram/Button/ButtonContained';

import { SCREEN_SIZE } from '../../lib/mediaQuery';
import { B2B } from '../../constants';

const MainContainer = styled.div`
  background: #ffffff;
  .button-contained {
    ${SCREEN_SIZE.Below.MobileL} {
      width: 100%;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem 0.5rem 1rem;

  ${SCREEN_SIZE.From.Tablet} {
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 2.5rem;
  }

  ${SCREEN_SIZE.From.Desktop} {
    max-width: 50rem;
    margin: 0px auto;
  }
`;

const Heading = styled(Typography).attrs({ variant: 'h1' })`
  text-align: center;
  margin-bottom: 0.75rem;
`;
const Body = styled(Typography).attrs({ variant: 'body2' })`
  text-align: center;
  max-width: 29rem;
  margin-bottom: 1.5rem;
`;

const LineBreak = styled.br`
  display: none;
  ${SCREEN_SIZE.From.MobileL} {
    display: block;
  }
`;

const StyledButtonsContainer = styled.div`
  margin-top: 1rem;
`;

const Image = styled.img`
  width: 100%;
`;

const Banner = ({ onSendNow, bannerImg }) => {
  return (
    <>
      <MainContainer>
        <Container>
          <Heading>
            Give something useful. <LineBreak />
            Send an E-Gift Voucher.
          </Heading>
          <Body>
            Send easily and quickly via email or SMS. Our E-Gift Vouchers can be used for multiple
            transactions online and at all FairPrice and Unity outlets.
          </Body>
          <ButtonContained
            className="button-contained"
            size="m"
            onClick={onSendNow}
            data-testid="banner-send-now"
          >
            Send now
          </ButtonContained>
          <StyledButtonsContainer>
            <Typography variant="bodyM">
              Corporate gifting?{' '}
              <Link size="l" href={`/?type=${B2B}`}>
                Find out more
              </Link>
            </Typography>
          </StyledButtonsContainer>
          <Image alt="Send an E-gift Voucher" src={bannerImg} />
        </Container>
      </MainContainer>
    </>
  );
};

export default Banner;
