import React from 'react';
import styled from 'styled-components';
import Typography from '@ntuctech/devex-tangram/Typography';
import Grid from '@ntuctech/devex-tangram/Grid';

import { SCREEN_SIZE } from '../../lib/mediaQuery';

import Flexible from '../icons/svg/Flexible.svg';
import Customisable from '../icons/svg/Cutsomisable.svg';
import Convenient from '../icons/svg/Convenient.svg';
import Versatile from '../icons/svg/Versatile.svg';

const MainContainer = styled.div`
  background: #ffffff;
  padding: 3.75rem 0;
`;

const StepIcon = styled.img`
  width: 5rem;
  height: 5rem;
  margin-bottom: 1rem;
`;

const StepInfo = styled.div`
  text-align: center;
  ${SCREEN_SIZE.Below.Tablet} {
    text-align: center;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    text-align: left;
    padding-left: 1rem;
    padding-right: 0.5rem;
    h5 {
      margin-bottom: 0.5rem;
    }
  }
`;
const GridItem = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.75rem;

  ${SCREEN_SIZE.Below.MobileL} {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
  }
`;

const Container = styled.div`
  .margin-top-y {
    margin: 4rem 0;
  }

  display: flex;
  max-width: 75rem;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  ${SCREEN_SIZE.From.Tablet} {
    margin-bottom: 0;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    padding: 0 1rem;
  }
`;

const Heading = styled(Typography).attrs({ variant: 'h2' })`
  text-align: center;
  margin-bottom: 4rem;
  ${SCREEN_SIZE.Below.Tablet} {
    margin-bottom: 3rem;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    margin-bottom: 2rem;
  }
`;

const HowItWorks = () => {
  return (
    <>
      <MainContainer>
        <Container>
          <Heading>Gifting made easy</Heading>
          <Grid container compact>
            <GridItem item s={3} xs={12}>
              <StepIcon src={Customisable.src} alt="Step1" />
              <StepInfo>
                <Typography variant="titleS">Customisable</Typography>
                <Typography variant="bodyM">
                  Choose from various designs and add a personal message.
                </Typography>
              </StepInfo>
            </GridItem>
            <GridItem item s={3} xs={12}>
              <StepIcon src={Convenient.src} alt="Step2" />
              <StepInfo>
                <Typography variant="titleS">Convenient</Typography>
                <Typography variant="bodyM">
                  Send them virtually anytime. Valid for 12 months.
                </Typography>
              </StepInfo>
            </GridItem>

            <GridItem item s={3} xs={12}>
              <StepIcon src={Versatile.src} alt="Step3" />
              <StepInfo>
                <Typography variant="titleS">Versatile</Typography>
                <Typography variant="bodyM">
                  Accepted at FairPrice Group stores, in-store and online.
                </Typography>
              </StepInfo>
            </GridItem>
            <GridItem item s={3} xs={12}>
              <StepIcon src={Flexible.src} alt="Step4" />
              <StepInfo>
                <Typography variant="titleS">Flexible</Typography>
                <Typography variant="bodyM">Can be used across multiple transactions.</Typography>
              </StepInfo>
            </GridItem>
          </Grid>
        </Container>
      </MainContainer>
    </>
  );
};

export default HowItWorks;
