import React from 'react';
import styled from 'styled-components';
import Typography from '@ntuctech/devex-tangram/Typography';
import Grid from '@ntuctech/devex-tangram/Grid';

import ButtonOutlined from '@ntuctech/devex-tangram/Button/ButtonOutlined';
import { SCREEN_SIZE } from '../../lib/mediaQuery';
import B2BSeamlessCorporateGifting from '../icons/svg/B2BSeamlessCorporateGifting.svg';

const MainContainer = styled.div`
  background: #ffffff;
`;

const Container = styled.div`
  display: flex;
  max-width: 75rem;
  flex-direction: column;
  align-items: center;
  margin: 0px auto;
  padding: 4rem 3rem;

  .applyBuisnessAccount {
    margin: 0;
    background-color: #ffffff;
  }

  ${SCREEN_SIZE.Below.Tablet} {
    margin-bottom: 0;
    padding: 3rem 1rem;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    margin-bottom: 0;
    padding: 2rem 1rem;
    .applyBuisnessAccount {
      width: 100%;
    }
  }
`;
const StepIcon = styled.img`
  max-width: 24rem;
  width: 100%;
  height: auto;
  align-self: flex-start;
  ${SCREEN_SIZE.Below.MobileL} {
    margin-bottom: 1rem;
    max-width: 100%;
  }
`;

const StepInfo = styled.div`
  text-align: right;

  h2 {
    margin-bottom: 0.75rem;
  }

  p {
    margin-bottom: 1.5rem;
  }

  ${SCREEN_SIZE.Below.MobileL} {
    text-align: left;
    width: 100%;
  }
`;
const GridItem = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 0 2rem;

  ${SCREEN_SIZE.Below.Tablet} {
    padding: 0 1rem;
  }

  ${SCREEN_SIZE.Below.MobileL} {
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    &:not(:last-child) {
      margin-bottom: 1rem;
      order: 1;
    }
  }
`;

const CorporateGifting = ({ handleB2BAction }) => {
  return (
    <>
      <MainContainer>
        <Container>
          <Grid container compact>
            <GridItem item s={6} xs={12}>
              <StepInfo>
                <Typography variant="h2">Seamless corporate gifting</Typography>
                <Typography variant="body2">
                  Order in bulk for your corporate event anytime.
                </Typography>
                <ButtonOutlined
                  size="m"
                  className="applyBuisnessAccount"
                  data-testid="applyBuisnessAccount"
                  onClick={() => handleB2BAction(true)}
                >
                  Apply for business account
                </ButtonOutlined>
              </StepInfo>
            </GridItem>
            <GridItem item s={6} xs={12}>
              <StepIcon src={B2BSeamlessCorporateGifting.src} />
            </GridItem>
          </Grid>
        </Container>
      </MainContainer>
    </>
  );
};

export default CorporateGifting;
