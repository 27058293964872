import { createContext, useContext } from 'react';
import { userInitialState } from '../reducers/userReducer';

const UserContext = createContext({
  userState: userInitialState,
});

export const useUserContext = () => useContext(UserContext);

export default UserContext;
