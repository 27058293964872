/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from 'styled-components';
import Typography from '@ntuctech/devex-tangram/Typography';

import Link from '@ntuctech/devex-tangram/Link';

import { SCREEN_SIZE } from '../../lib/mediaQuery';
import Accordion from '../Accordion';

const MainContainer = styled.div`
  background: #ffffff;
`;

const Container = styled.div`
  max-width: 75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px auto;
  padding: 4rem 3rem;
  ${SCREEN_SIZE.Below.Tablet} {
    margin-bottom: 0;
    padding: 3rem 2rem;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    margin-bottom: 0;
    padding: 2rem 1rem;
  }
`;

const Heading = styled(Typography).attrs({ variant: 'h2' })`
  text-align: center;
  margin-bottom: 4rem;
  ${SCREEN_SIZE.Below.Tablet} {
    margin-bottom: 3rem;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    margin-bottom: 2rem;
  }
`;

const FAQLink = styled(Link).attrs({ size: 'l' })`
  font-weight: 400;
  margin-left: 0.25rem;
`;

const LoadMore = styled(Link).attrs({ size: 'l' })`
  margin-top: 0.5rem;
`;

const MoreInfo = styled.div`
  padding: 1rem;
  background-color: #f1f6fe;
  color: #333;
  width: 100%;
  border-radius: 0.25rem;
  border: 1px solid #f1f6fe;
  margin-bottom: 1.125rem;
`;

const handleLandingPageNavigation = (mode) => {
  if (mode === 'B2B') {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  } else {
    window.scrollTo({ top: 0 });
    window.location.href = `/?type=${mode}`;
  }
};

const faqData = [
  {
    id: 1,
    question: 'What are FairPrice Group E-Gift Vouchers?',
    answer: (
      <Typography variant="bodyM">
        E-Gift Vouchers are prepaid digital vouchers that can be used at FairPrice stores, Warehouse
        Club, Unity stores and{' '}
        <Link size="l" href="https://fairprice.com.sg/">
          www.fairprice.com.sg
        </Link>
        . Recipients can tap on the E-Gift Voucher link to view the E-Gift Vouchers in a web browser
        or store them in the FairPrice Group app for seamless redemptions in-stores and online
        anytime.
      </Typography>
    ),
  },
  {
    id: 2,
    question: 'What types of vouchers can I purchase online?',
    answer: (
      <Typography variant="bodyM">
        You may purchase E-Gift Vouchers for Business in bulk via this{' '}
        <Link size="l" data-testid="b2b-link" onClick={() => handleLandingPageNavigation('B2B')}>
          page
        </Link>
        . To purchase personal E-Gift Vouchers, please visit this{' '}
        <Link size="l" data-testid="b2c-link" onClick={() => handleLandingPageNavigation('B2C')}>
          page
        </Link>
        .
        <br />
        <br />
        Looking to make bulk orders for physical gift cards and vouchers as corporate gifts? You
        can:
        <ul style={{ listStyle: 'disc', paddingLeft: 40, marginTop: 8 }}>
          <li>
            Email us at{' '}
            <Link size="l" href="mailto:fpvouchers@ntucenterprise.sg?subject=E-Gift Voucher Order">
              fpvouchers@ntucenterprise.sg
            </Link>{' '}
            to indicate your intend to purchase, or
          </li>
          <li>Submit the following order forms:</li>
          <ul style={{ listStyle: 'circle', paddingLeft: 40, marginTop: 8 }}>
            <li>
              <Link
                size="l"
                href="https://docs.google.com/forms/d/e/1FAIpQLSe7leIUNS8cHizmzzj2i5HxeYCgp6Y4b0Vd0KhJd_qXl71f4g/viewform"
              >
                Gift vouchers
              </Link>
            </li>
            <li>
              <Link
                size="l"
                href="https://docs.google.com/forms/d/e/1FAIpQLScnIkfSvfxCmayvF8ef5QxAC6gYZsiBHovKPe3bqklrQIKOGA/viewformGift cards"
              >
                Gift cards
              </Link>
            </li>
          </ul>
        </ul>
      </Typography>
    ),
  },
  {
    id: 3,
    question: 'What are the accepted payment methods?',
    answer: (
      <Typography variant="bodyM">
        We accept the following payments for orders made via the website:
        <ol style={{ marginTop: 8 }}>
          <li>
            <strong>Credit or debit card</strong> (Receive vouchers instantly)
          </li>
          <li>
            <strong>Bank transfer</strong> (2-5 working days processing time)
          </li>
        </ol>
        Payment via Vendors@Gov is currently not available. To use other payment methods, please
        email{' '}
        <Link size="l" href="mailto:fpvouchers@ntucenterprise.sg">
          fpvouchers@ntucenterprise.sg
        </Link>
        .
      </Typography>
    ),
  },
  {
    id: 4,
    question: 'What is a Business account?',
    answer: (
      <Typography variant="bodyM">
        A Business account on the FairPrice Group E-Gift Vouchers page allows employees to place
        orders for E-Gift Vouchers in bulk on behalf of their businesses or organisations.
        <br />
        <br />
        With the Business account, you can now purchase, manage, and distribute these E-Gift
        Vouchers hassle-free!
      </Typography>
    ),
  },
  {
    id: 5,
    question: 'How are the E-Gift Vouchers issued?',
    answer: (
      <Typography variant="bodyM">
        Upon successful payment, you will receive 2 emails:
        <ul style={{ listStyle: 'disc', paddingLeft: 40 }}>
          <li>
            Email 1: Password-protected file with your purchased E-Gift Voucher details (i.e.
            voucher URLs, voucher codes, denomination, and expiry dates).
          </li>
          <li>
            Email 2: Password to access E-Gift Vouchers in Email 1. If you’ve indicated in your
            order for another email address to receive this password, this email will be sent to
            them.
          </li>
        </ul>
      </Typography>
    ),
  },
  {
    id: 6,
    question: 'How soon will I receive my order?',
    answer: (
      <Typography variant="bodyM">
        This depends on the payment method used. If you paid via
        <br />
        <ul style={{ listStyle: 'disc', paddingLeft: 40 }}>
          <li>
            <strong>Credit or debit card:</strong> You should receive the E-Gift Vouchers within a
            few minutes upon successful payment.
          </li>
          <li>
            <strong>Bank transfer:</strong> A processing time of 2-5 business days is required. You
            should receive the E-Gift Vouchers once your bank confirms the payment.
          </li>
        </ul>
      </Typography>
    ),
  },
  {
    id: 7,
    question: 'Is there a minimum purchase amount in order to make corporate gifting orders?',
    answer: (
      <Typography variant="bodyM">
        With a Business account, you can easily send E-Gift Vouchers for Business from as low as $1
        in a single E-Gift Voucher.
      </Typography>
    ),
  },
  {
    id: 8,
    question: 'Are there discounts for bulk or corporate purchase of E-Gift Vouchers?',
    answer: (
      <Typography variant="bodyM">
        Please contact the FairPrice Group E-Vouchers sales team at{' '}
        <Link size="l" href="mailto:fpvouchers@ntucenterprise.sg?subject=E-Gift Voucher Order">
          fpvouchers@ntucenterprise.sg
        </Link>{' '}
        for more information.
      </Typography>
    ),
  },
  {
    id: 9,
    question: 'Will I receive an e-invoice for my order?',
    answer: (
      <Typography variant="bodyM">
        Yes! Once you’ve placed your order, you can download a copy of your invoice upon successful
        payment. We will also send a copy of your invoice to your email.
      </Typography>
    ),
  },
  {
    id: 10,
    question: 'Do recipients need to download the FairPrice Group app to use the E-Gift Voucher?',
    answer: (
      <>
        <Typography variant="bodyM">
          You don’t need to download the FairPrice Group app to use the E-Gift Vouchers! They are
          accessible via a standard web browser.
        </Typography>
        <br />
        <br />
        <Typography variant="bodyM">
          However, having the FairPrice Group app allows you to conveniently store your E-Gift
          Voucher in your account, keeping it within reach for seamless redemptions in-stores and
          online anytime!
        </Typography>
      </>
    ),
  },
];

const B2BFAQs = () => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 600);
  React.useEffect(() => {
    const updateWindowDimensions = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  const initialFaq = isMobile ? 3 : 5;
  const faqCount = faqData?.length;
  const [loadAllFAQ, setLoadAllFAQ] = React.useState(initialFaq);
  return (
    <>
      <MainContainer>
        <Container>
          <Heading>Frequently asked questions</Heading>
          {faqData.slice(0, loadAllFAQ).map((item) => (
            <Accordion title={item.question} key={item.question} b2b>
              <div>{item.answer}</div>
            </Accordion>
          ))}
          {loadAllFAQ === faqCount ? (
            <MoreInfo>
              For more information, visit our
              <FAQLink
                target="_blank"
                href="https://help.fairprice.com.sg/hc/en-us/sections/9514637359385-FairPrice-E-Gift-Vouchers"
              >
                Help Centre
              </FAQLink>
              .
            </MoreInfo>
          ) : null}
          {loadAllFAQ === initialFaq ? (
            <LoadMore onClick={() => setLoadAllFAQ(faqCount)}>Show all FAQs</LoadMore>
          ) : (
            <LoadMore onClick={() => setLoadAllFAQ(initialFaq)}>Show less FAQs</LoadMore>
          )}
        </Container>
      </MainContainer>
    </>
  );
};

export default B2BFAQs;
