import React from 'react';
import styled from 'styled-components';
import { Grid, Link } from '@ntuctech/devex-tangram';
import Typography from '@ntuctech/devex-tangram/Typography';
import ButtonContained from '@ntuctech/devex-tangram/Button/ButtonContained';
import B2BBannerImage from '../icons/svg/B2BBanner.svg';

import { SCREEN_SIZE } from '../../lib/mediaQuery';
import { AMP_EVENT_NAME } from '../../lib/amplitude';
import { GTM_ENV_VAR as GEV } from '../../lib/eventTrackerFunction';

const MainContainer = styled.div`
  background: #0b2e66;
  color: #ffffff;
`;

const Container = styled.div`
  max-width: 75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4rem 1rem;
  margin: 0 auto;

  .order-now-button {
    margin: 0;
    margin-bottom: 1.5rem;
    width: 10.8125rem;
  }

  .banner-image {
    width: 100%;
    height: auto;
  }

  .b2b-banner-grid {
    align-items: center;
  }

  ${SCREEN_SIZE.Below.Tablet} {
    padding: 3rem 0rem;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    padding: 2rem 0rem;
    flex-direction: column;
    .order-now-button {
      width: 100%;
    }
  }
`;

const Heading = styled(Typography).attrs({ variant: 'titleXxl' })`
  margin-bottom: 0.5rem;
  color: #ffffff;
`;
const Body = styled(Typography).attrs({ variant: 'body2' })`
  margin-bottom: 1.5rem;
  color: #ffffff;
`;

const ContentContainer = styled.div`
  width: 100%;
  align-items: flex-start;
  display: flex;
  text-align: left;
  flex-direction: column;
`;

const ApplyNow = styled.div`
  a:link,
  a:visited {
    color: #ffffff;
    text-decoration: underline;
  }
`;

const Image = styled.img`
  width: 100%;
  ${SCREEN_SIZE.Below.MobileL} {
    margin-top: 2rem;
  }
`;

const ApplyNowButton = styled(Link)`
  color: #ffffff;
  cursor: pointer;
  text-decoration: underline;
`;

const B2BBanner = ({ isEntityDataAvailable, handleB2BAction, track }) => {
  return (
    <>
      <MainContainer>
        <Container>
          <Grid container className="b2b-banner-grid">
            <Grid item s={6} xs={12}>
              <ContentContainer>
                <Heading>The perfect corporate gift for everyone</Heading>
                <Body>
                  Order in bulk for your event and receive it via email instantly. Easily send
                  whenever you like.
                </Body>
                <ButtonContained
                  size="m"
                  onClick={() => handleB2BAction()}
                  className="order-now-button"
                  data-testid="banner-send-now"
                  darkMode
                >
                  Order now
                </ButtonContained>
                {!isEntityDataAvailable && (
                  <ApplyNow>
                    Don’t have a Business account?{' '}
                    <ApplyNowButton
                      onClick={() => {
                        handleB2BAction(true);
                        track({
                          [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.APPLY_NOW_CLICKED,
                          [GEV.MEMBER_TYPE]: 'B2B',
                          [GEV.AMPLITUDE_VERTICAL]: AMP_EVENT_NAME.VERTICAL,
                          [GEV.AMPLITUDE_CTA_LOCATION]: 'egifting | home',
                          [GEV.AMPLITUDE_CTA_SUB_LOCATION]: null,
                        });
                      }}
                    >
                      Apply now
                    </ApplyNowButton>
                  </ApplyNow>
                )}
              </ContentContainer>
            </Grid>
            <Grid item s={6} xs={12}>
              <Image
                alt="Send an E-gift Voucher"
                className="banner-image"
                src={B2BBannerImage.src}
              />
            </Grid>
          </Grid>
        </Container>
      </MainContainer>
    </>
  );
};

export default B2BBanner;
