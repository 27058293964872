import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import Chip from '@ntuctech/devex-tangram/Chip';
import Typography from '@ntuctech/devex-tangram/Typography';
import Grid from '@ntuctech/devex-tangram/Grid';
import { SCREEN_SIZE } from '../../lib/mediaQuery';
import B2BPlayButtonIMG from '../../static/playbutton.svg';
import { isPastDate } from '../../utils/Utils';

const B2BMainContainer = styled.div`
  background: ${(p) => p.theme.ColorBaseMineShaft40};
`;

const Container = styled.div`
  max-width: 59rem;
  padding: 4rem 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px auto;
  ${SCREEN_SIZE.Below.Tablet} {
    margin-bottom: 0;
    padding: 3rem 1.5rem;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    margin-bottom: 0;
    padding: 2rem 0.5rem;
    .contactUs {
      width: 100%;
    }
  }
  .gift-cards-center {
    justify-content: center;
  }
`;

const B2BHeading = styled(Typography).attrs({ variant: 'h2' })`
  text-align: center;
  margin-bottom: 3.5rem;
  ${SCREEN_SIZE.Below.Tablet} {
    margin-bottom: 2.5rem;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    margin-bottom: 1.5rem;
  }
`;

const B2BContentTag = styled.div`
  margin-bottom: 0.5rem;
  padding: 0.5rem 0.5rem 0;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  overflow: auto;
  position: sticky;
  top: 0;
  background: ${(p) => p.theme.ColorBaseMineShaft40};
  width: 100%;
  justify-content: center;
`;

const Category = styled(Chip)`
  margin: 0 0.5rem 0.5rem 0;
  text-transform: capitalize;
  white-space: nowrap;
`;

const B2BGridItem = styled(Grid).attrs({ s: 3, xs: 6, item: true })`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem !important;
`;

const B2BCardImg = styled.img`
  width: 100%;
  object-fit: contain;
  border-radius: 8px;
  cursor: pointer;
  display: block;
`;
const B2BHoverBox = styled.div`
  cursor: pointer;
  display: none;
`;
const B2BImgWrapper = styled.div`
  cursor: pointer;
  :hover {
    border-radius: 8px;
    position: relative;
    top: 3px;
    img {
      display: block;
      border-radius: 11px;
      top: -3px;
      position: relative;
    }
    > div {
      display: block;
      border: 3px solid #fff;
      border-radius: 8px;
      position: absolute;
      top: -2px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      z-index: 9;
      left: 1px;
      box-shadow: 0 0 0 1px #1454b8;
    }
  }
`;

const B2BPlayButton = styled.div`
  img {
    width: 3rem !important;
    height: 3rem !important;
    position: absolute;
    left: 40%;
    top: 35%;
  }
`;

const B2BGIFCard = styled.div`
  position: relative !important;
  height: auto;
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 8px;
    cursor: pointer;
    display: block;
  }
`;

const B2BGiftVoucher = ({ data = [], handleB2BAction, isB2c }) => {
  const [selectedB2BCategory, setSelectedB2BCategory] = useState('all');
  const [gifB2BData, setGifB2BData] = useState({});
  const [isB2BMobile, setIsB2BMobile] = React.useState(window.innerWidth < 600);

  React.useEffect(() => {
    const updateB2BWindowDimensions = () => {
      setIsB2BMobile(window.innerWidth < 600);
    };

    window.addEventListener('resize', updateB2BWindowDimensions);

    return () => window.removeEventListener('resize', updateB2BWindowDimensions);
  }, []);

  // Filter vouchers
  // by selected category
  const cardsB2BData = useMemo(
    () =>
      selectedB2BCategory === 'all'
        ? data.filter((b2bItem) => b2bItem.type !== 'banner')
        : data.filter(
            (b2bItem) => b2bItem.catergory === selectedB2BCategory && b2bItem.type !== 'banner'
          ),
    [selectedB2BCategory, data]
  );

  const getB2BFileExtension = (filename) => {
    return filename.split('.').pop();
  };

  const grouping = data.filter(
    (item) => !isPastDate(item?.end, true) && isPastDate(item?.start, true)
  );
  const isEnableB2BCategories = grouping.filter(
    (tag, index, array) => array.findIndex((item) => item.catergory === tag.catergory) === index
  );
  isEnableB2BCategories.unshift({
    catergory: 'all',
    title: 'All',
    start: '1970-01-01',
  });
  return (
    <>
      <B2BMainContainer>
        <Container>
          <B2BHeading>Pick a design</B2BHeading>
          <B2BContentTag data-testid="tags">
            {isEnableB2BCategories.map((category) => (
              <Category
                data-testid={`voucher-category-${category.catergory}`}
                label={category.title}
                key={`${category.title}-chip`}
                selected={category.catergory === selectedB2BCategory}
                onClick={() => {
                  setSelectedB2BCategory(category.catergory);
                }}
              />
            ))}
          </B2BContentTag>
          <Grid className={cardsB2BData?.length < 4 ? 'gift-cards-center' : ''} container compact>
            {cardsB2BData.map((option, index) => {
              const isSelected = gifB2BData.isPlaying && index === gifB2BData.index;
              const desktopSrc = isSelected ? cardsB2BData[gifB2BData.index].png : option.image;
              const isExpired = isPastDate(option?.end, true);
              const isStarted = isPastDate(option?.start, true);
              return (
                <React.Fragment key={`${option.png}-${option.category}`}>
                  {!isExpired && isStarted ? (
                    <>
                      {getB2BFileExtension(option.png) === 'gif' ? (
                        <B2BGridItem
                          onMouseOver={() =>
                            setGifB2BData((prevState) => ({
                              index,
                              isPlaying: !prevState.isPlaying,
                            }))
                          }
                          onMouseOut={() =>
                            setGifB2BData((prevState) => ({
                              index,
                              isPlaying: !prevState.isPlaying,
                            }))
                          }
                          onClick={() => {
                            handleB2BAction(false, option, isB2c);
                          }}
                        >
                          <B2BGIFCard data-testid={`play-button-${index}`}>
                            <B2BCardImg
                              src={isB2BMobile ? option.png : desktopSrc}
                              alt={option.name}
                            />
                            {isSelected || isB2BMobile ? (
                              ''
                            ) : (
                              <B2BPlayButton>
                                <img src={B2BPlayButtonIMG.src} alt="test" />
                              </B2BPlayButton>
                            )}
                          </B2BGIFCard>
                        </B2BGridItem>
                      ) : (
                        <B2BGridItem
                          key={option.id}
                          onClick={() => {
                            handleB2BAction(false, option, isB2c);
                          }}
                          data-testid="voucher-image"
                        >
                          <B2BImgWrapper>
                            <B2BHoverBox />
                            <B2BCardImg src={option.image} alt={option.name} />
                          </B2BImgWrapper>
                        </B2BGridItem>
                      )}
                    </>
                  ) : null}
                </React.Fragment>
              );
            })}
          </Grid>
        </Container>
      </B2BMainContainer>
    </>
  );
};

export default B2BGiftVoucher;
