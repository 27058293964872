import React from 'react';
import styled from 'styled-components';
import Typography from '@ntuctech/devex-tangram/Typography';
import ButtonContained from '@ntuctech/devex-tangram/Button/ButtonContained';

import { SCREEN_SIZE } from '../../lib/mediaQuery';

const MainContainer = styled.div`
  background: ${(p) => p.theme.ColorBaseMineShaft40};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .button-send-now {
    margin-top: 1.5rem;
    width: 10rem;
  }
  .brightens {
    ${SCREEN_SIZE.Below.MobileL} {
      text-align: center;
    }
  }
  margin: 4rem 0;
  ${SCREEN_SIZE.Below.MobileL} {
    margin: 2.75rem 0;
    padding: 0 1rem;
    .button-send-now {
      margin-top: 1.5rem;
      width: 100%;
    }
  }
`;
const CorporateVoucher = ({ router }) => {
  const sendNow = () => {
    const windowVar = typeof window !== 'undefined' && window;
    router?.push('/customise-voucher/');
    windowVar.location.href = '/customise-voucher/';
  };

  return (
    <>
      <MainContainer>
        <Container>
          <Typography className="brightens" variant="titleM">
            Brighten someone’s day with an E-Gift Voucher
          </Typography>
          <ButtonContained
            className="button-send-now"
            size="m"
            onClick={() => sendNow()}
            data-testid="banner-send-now-corporate"
          >
            Send now
          </ButtonContained>
        </Container>
      </MainContainer>
    </>
  );
};

export default CorporateVoucher;
