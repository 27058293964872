import React from 'react';
import styled from 'styled-components';
import Typography from '@ntuctech/devex-tangram/Typography';
import Grid from '@ntuctech/devex-tangram/Grid';

import Link from '@ntuctech/devex-tangram/Link';

import { SCREEN_SIZE } from '../../lib/mediaQuery';
import Accordion from '../Accordion';

const MainContainer = styled.div`
  background: #ffffff;
  ${SCREEN_SIZE.Below.MobileL} {
    padding-bottom: 2rem;
  }
`;

const Container = styled.div`
  .margin-y-64 {
    margin: 4rem 0;
  }
  .text-center {
    text-align: center;
  }
  display: flex;
  flex-direction: column;
  align-items: center;

  ${SCREEN_SIZE.From.Desktop} {
    max-width: 39rem;
    margin: 0px auto;
    margin-bottom: 4rem !important;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    .margin-y-64 {
      margin: 2rem 0;
    }
    padding: 0 1rem;
  }
`;

const FAQLink = styled(Link).attrs({ size: 'l' })`
  margin-top: 1.5rem;
`;

const faqData = [
  {
    id: 1,
    question: 'What are FairPrice E-Gift Vouchers?',
    answer:
      'FairPrice E-Gift Vouchers are prepaid digital vouchers that you can give your customers, guests, employees, and loved ones as gifts.',
  },
  {
    id: 2,
    question: 'Where can I buy FairPrice E-Gift Vouchers?',
    answer: (
      <div>
        You can purchase E-Gift Voucher from
        <ol>
          <li>Fairprice Group app &gt; Rewards tab &gt; Send E-Gift Vouchers</li>
          <li>FairPrice web &gt; More &gt; Send E-Gift Vouchers</li>
        </ol>
        Simply select the E-Gift Voucher of your choice, add to cart and checkout.
      </div>
    ),
  },
  {
    id: 3,
    question: 'How can I pay for my FairPrice E-Gift Voucher purchase?',
    answer: 'You can pay using your Credit Card or Debit Card.',
  },
  {
    id: 4,
    question: 'Can I use the FairPrice E-Gift Vouchers myself?',
    answer: 'Yes.',
  },
  {
    id: 5,
    question: 'Can I use my Linkpoints to buy FairPrice E-Gift Vouchers?',
    answer: 'No.',
  },
];

const FAQs = () => {
  return (
    <>
      <MainContainer>
        <Container>
          <Grid className="margin-y-64 text-center">
            <Typography variant="titleXl">Frequently asked questions</Typography>
          </Grid>
          {faqData.map((item) => (
            <Accordion title={item.question} key={item.question}>
              <div>{item.answer}</div>
            </Accordion>
          ))}
          <FAQLink href="https://help.fairprice.com.sg/hc/en-us/sections/9514637359385-FairPrice-E-Gift-Vouchers">
            Show all FAQs
          </FAQLink>
        </Container>
      </MainContainer>
    </>
  );
};

export default FAQs;
