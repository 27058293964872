import React from 'react';
import styled from 'styled-components';
import { Typography } from '@ntuctech/devex-tangram';

import Success from '../icons/svg/Success.svg';
import Recommended from '../icons/svg/recommended.svg';

const ORDER_STATUS_HISTORY = 'Order status history';
const PROCESSING_TIME = 'Processing time';
const VOUCHER_QUANTITY_LIMIT = 'Voucher quantity limit (per order)';
const MAX_VALUE_PER_VOUCHER = 'Max. value per voucher';

const RecommendedContainer = styled.div`
  width: 6.063rem;
  padding-top: 0.25rem;
  margin: 0 auto;
`;

// eslint-disable-next-line import/prefer-default-export
export const headers = [
  <Typography className="relative-top" variant="titleS" id="payment-method-header">
    Payment method
  </Typography>,
  <Typography variant="titleS" id="credit-or-debit-header">
    Credit or Debit card
    <RecommendedContainer>
      <img src={Recommended.src} alt="Recommended" />
    </RecommendedContainer>
  </Typography>,
  <Typography className="relative-top" variant="titleS" id="bank-transfer-header">
    Bank transfer
  </Typography>,
];
export const rows = [
  {
    id: 0,
    values: [
      {
        text: PROCESSING_TIME,
        key: 'processing-time',
      },
      {
        text: 'Instant',
        key: 'instant',
      },
      {
        text: '2-5 working days',
        key: '2-5-working-days',
      },
    ],
  },
  {
    id: 1,
    values: [
      {
        text: VOUCHER_QUANTITY_LIMIT,
        key: 'voucher-quantity-limit',
      },
      {
        text: '400,000',
        key: 'amount',
      },
      {
        text: '400,000',
        key: 'amount-2',
      },
    ],
  },
  {
    id: 2,
    values: [
      {
        text: MAX_VALUE_PER_VOUCHER,
        key: 'max-value-per-voucher',
      },
      {
        text: '$1,000',
        key: 'amount',
      },
      {
        text: '$1,000',
        key: 'amount-2',
      },
    ],
  },
  {
    id: 3,
    values: [
      {
        text: ORDER_STATUS_HISTORY,
        key: 'order-status-history',
      },
      {
        text: <img src={Success.src} className="img" alt="success" />,
        key: 'success',
      },
      {
        text: <img src={Success.src} className="img" alt="success" />,
        key: 'success-2',
      },
    ],
  },
];

export const mobileRows = [
  {
    title: PROCESSING_TIME,
    body: 'Instant',
  },
  {
    title: VOUCHER_QUANTITY_LIMIT,
    body: '400,000',
  },
  {
    title: MAX_VALUE_PER_VOUCHER,
    body: '$1,000',
  },
  {
    title: ORDER_STATUS_HISTORY,
    body: <img src={Success.src} className="img" alt="success" />,
  },
];

export const mobileRowsBankTransfer = [
  {
    title: PROCESSING_TIME,
    body: '2-5 working days',
  },
  {
    title: VOUCHER_QUANTITY_LIMIT,
    body: '400,000',
  },
  {
    title: MAX_VALUE_PER_VOUCHER,
    body: '$1,000',
  },
  {
    title: ORDER_STATUS_HISTORY,
    body: <img src={Success.src} className="img" alt="success" />,
  },
];
