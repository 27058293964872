import React from 'react';
import styled from 'styled-components';
import Modal from '@ntuctech/devex-tangram/Modal';
import ModalTitle from '@ntuctech/devex-tangram/ModalTitle';
import ModalContent from '@ntuctech/devex-tangram/ModalContent';
import ModalActions from '@ntuctech/devex-tangram/ModalActions';
import Typography from '@ntuctech/devex-tangram/Typography';
import ButtonContained from '@ntuctech/devex-tangram/Button/ButtonContained';
import { ButtonText } from '@ntuctech/devex-tangram';
import { GTM_ENV_VAR as GEV } from '../../lib/eventTrackerFunction';
import { AMP_EVENT_NAME } from '../../lib/amplitude';

const ModalText = styled.div`
  padding-bottom: 0.75rem;
`;

const ApplyNowPopup = (props) => {
  const { open, closeModal, applyNowURL, track } = props;

  const openLink = (isClose = false) => {
    track({
      [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.BUSINESS_ACCOUNT_POPUP,
      [GEV.MEMBER_TYPE]: 'B2B',
      [GEV.AMPLITUDE_VERTICAL]: AMP_EVENT_NAME.VERTICAL,
      [GEV.AMPLITUDE_CTA_LOCATION]: 'egifting | home',
      [GEV.AMPLITUDE_CTA_SUB_LOCATION]: null,
      [GEV.AMPLITUDE_GIFTING_MESSAGE]: isClose ? 'close' : 'apply now',
    });
    if (!isClose) {
      window.open(applyNowURL, '_blank');
    }

    closeModal();
  };

  return (
    <Modal id="applyNowPopupId" open={open}>
      <ModalTitle>
        <ModalText>
          <Typography variant="h4">
            You need a Business account to purchase E-Gift Vouchers for Business.
          </Typography>
        </ModalText>
      </ModalTitle>
      <ModalContent>
        <ModalText>
          <Typography variant="bodyLRegular">
            Apply now and get approval within 3 working days.
          </Typography>
        </ModalText>
      </ModalContent>
      <ModalActions>
        <ButtonText size="m" onClick={() => openLink(true)}>
          Close
        </ButtonText>
        <ButtonContained data-testid="apply-now-btn" onClick={() => openLink()} size="m">
          Apply now
        </ButtonContained>
      </ModalActions>
    </Modal>
  );
};

export default ApplyNowPopup;
