import { isEmpty } from 'lodash';
import { GUEST_DATA } from '../../../constants';
import { getCookie, safeJSONParse } from '../../utils';

export const userInitialState = {
  auth0Token: '',
  isLoggingIn: true,
  user: null,
  guest: isEmpty(getCookie(GUEST_DATA)) ? null : safeJSONParse(getCookie(GUEST_DATA)),
};

export default function userReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case 'USER_LOGIN_SUCCESS':
      return {
        ...state,
        auth0Token: payload.auth0Token,
        user: payload.user,
        isLoggingIn: false,
      };
    case 'USER_LOGIN_FAILURE':
      return {
        ...state,
        auth0Token: '',
        user: null,
        isLoggingIn: false,
      };
    case 'SET_GUEST':
      return { ...state, guest: payload, isLoggingIn: false };
    default:
      return state;
  }
}
