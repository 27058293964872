import React from 'react';
import styled from 'styled-components';
import { Typography } from '@ntuctech/devex-tangram';
import Logo from '../icons/svg/logo_fairprice_logooriginal.svg';
import UnityLogo from '../icons/svg/logo_unity.svg';
import XtraLogo from '../icons/svg/logo_fairprice_xtra.svg';
import FinestLogo from '../icons/svg/logo_fairprice_finest.svg';
import ShopLogo from '../icons/svg/logo_fairprice_fairpriceshop.svg';

import { SCREEN_SIZE } from '../../lib/mediaQuery';

const MainContainer = styled.div`
  background: #ffffff;
  .no-spacing {
    margin: 0;
    padding: 0;
  }
  ${SCREEN_SIZE.From.Tablet} {
    .right {
      text-align: right;
    }
  }
  ${SCREEN_SIZE.Below.MobileL} {
    .center {
      text-align: center;
    }
  }
`;

const Container = styled.div`
  max-width: 75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px auto;
  padding: 4rem 3rem;
  ${SCREEN_SIZE.Below.Tablet} {
    margin-bottom: 0;
    padding: 3rem 2rem;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    margin-bottom: 0;
    padding: 3rem 1rem;
  }
`;

const Heading = styled(Typography).attrs({ variant: 'h2' })`
  text-align: center;
  margin-bottom: 4rem;
  ${SCREEN_SIZE.Below.Tablet} {
    margin-bottom: 3rem;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    margin-bottom: 2rem;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  img {
    height: 2.25rem;
    width: auto;
    margin: 0 1rem;
    margin-bottom: 2rem;
    ${SCREEN_SIZE.Below.MobileL} {
      max-width: 8.125rem;
      height: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
`;

const B2BStores = () => {
  return (
    <>
      <MainContainer>
        <Container>
          <Heading>Accepted in-store and online</Heading>
          <ImageContainer>
            <img src={Logo.src} alt="fairprice logo" />
            <img src={XtraLogo.src} alt="fairprice xtra logo" />
            <img src={FinestLogo.src} alt="fairprice finest logo" />
            <img src={ShopLogo.src} alt="fairprice shop logo" />
            <img src={UnityLogo.src} alt="unity logo" />
          </ImageContainer>
        </Container>
      </MainContainer>
    </>
  );
};

export default B2BStores;
