import React from 'react';
import styled from 'styled-components';
import { Typography } from '@ntuctech/devex-tangram';

import { SCREEN_SIZE } from '../../lib/mediaQuery';
import Recommended from '../icons/svg/recommended.svg';
import { mobileRows, mobileRowsBankTransfer } from './utils';

const MainContainer = styled.div`
  background: #ffffff;
  table {
    border: 1px solid #cccccc;
    border-radius: 0.75rem;
    border-spacing: 0;
  }

  tr:first-child th:first-child {
    border-top-left-radius: 0.71rem;
  }
  tr:first-child td:not(:first-child) {
    text-align: center;
  }

  tr:first-child th:last-child {
    border-top-right-radius: 0.71rem;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 0.75rem;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 0.75rem;
  }

  .img {
    width: 1.5rem;
    height: 1.5rem;
  }

  .relative-top {
    position: relative;
    bottom: 1rem;
  }

  tr:nth-child(1) td {
    border-bottom: 1px solid #eaeaea;
  }

  tr:nth-child(1) th:not(:last-child) {
    border-right: 1px solid #eaeaea;
  }

  tr:nth-child(1) td:not(:last-child) {
    border-right: 1px solid #eaeaea;
  }

  tr:nth-child(2) td {
    border-bottom: 1px solid #eaeaea;
  }

  tr:nth-child(2) td:not(:last-child) {
    border-right: 1px solid #eaeaea;
  }

  tr:nth-child(3) td {
    border-bottom: 1px solid #eaeaea;
  }

  tr:nth-child(3) td:not(:last-child) {
    border-right: 1px solid #eaeaea;
  }

  tr:nth-child(4) td:not(:last-child) {
    border-right: 1px solid #eaeaea;
  }

  ${SCREEN_SIZE.Below.MobileL} {
    table {
      border: 0;
    }
    td {
      border: 1px solid #cccccc;
    }
    tr td:first-child {
      border-bottom: 0;
    }
    tr:last-child td:last-child {
      border-bottom: 1px solid #cccccc;
    }
  }
`;

const StyledTable = styled.table`
  max-width: 930px;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  max-width: 75rem;
  flex-direction: column;
  align-items: center;
  margin: 0px auto;
  padding: 4rem 3rem;
  ${SCREEN_SIZE.Below.Tablet} {
    margin-bottom: 0;
    padding: 3rem 2rem;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    margin-bottom: 0;
    padding: 2rem 1rem;
  }
`;

const StyledRow = styled.tr`
  background-color: #1454b8;
  h5 {
    color: #ffffff;
  }
`;

const StyledHeader = styled.th`
  padding: 1rem 1rem 0.375rem;
  ${SCREEN_SIZE.From.Desktop} {
    padding: 1rem 1.5rem 0.375rem;
  }
`;

const StyledTableData = styled.td`
  padding: 1rem 1rem 0.75rem;
  ${(p) => p.isNotLast && 'text-align: center;'}
  ${SCREEN_SIZE.From.Desktop} {
    padding: 1.25rem 1.5rem 0.75rem;
  }
`;

const Title = styled.div`
  margin: 0 0 4rem 0;
  ${SCREEN_SIZE.Below.MobileL} {
    text-align: center;
    margin: 0 0 1.5rem 0;
  }
`;

const MobileTable = styled.table`
  width: 100%;
  border-radius: 12px 12px 0px 0px;
  overflow: hidden;
  ${(p) => p.marginTop && 'margin-top: 1rem;'}
`;
const MobileHeader = styled.th`
  padding: 1rem 1rem 0.75rem;
  background-color: #1454b8;
  h5 {
    color: #ffffff;
    margin-bottom: 0.25rem;
    text-align: left;
  }
  img {
    height: 1.5rem;
    width: auto;
    margin-left: auto;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    padding: 1rem;
  }
`;

const WhiteColor = styled(Typography)`
  color: #ffffff;
  display: flex;
  align-items: center;
`;

const StyledMobileData = styled.td`
  padding: 0.75rem 0.75rem 0;
  h5 {
    margin-bottom: 0.25rem;
  }
  ${(p) => p.isLast && 'padding-bottom: 1rem;'};

  border-top: 0 !important;
`;

const StyledHR = styled.hr`
  border: none;
  border-top: 1px solid #eaeaea;
  margin-top: 0.75rem;
  margin-bottom: 0;
`;

const TableComparison = ({ headers, rows }) => {
  const [isMobile, setIsMobile] = React.useState(window?.innerWidth <= 767);

  React.useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth <= 767);
    });

    return () => window.removeEventListener('resize', () => {});
  }, []);
  return (
    <MainContainer>
      <Container>
        <Title>
          <Typography variant="titleXl">More ways to pay that suit your needs</Typography>
        </Title>
        {isMobile ? (
          <>
            <MobileTable>
              <tr>
                <MobileHeader>
                  <Typography variant="titleS">Payment method</Typography>
                  <WhiteColor variant="bodyL">
                    Credit or Debit card
                    <img src={Recommended.src} alt="Recommended" />
                  </WhiteColor>
                </MobileHeader>
              </tr>
              <tbody>
                {mobileRows.map((mr, ind) => {
                  const isLast = ind === mobileRows.length - 1;
                  return (
                    <tr key={`${mr.title}-table-row`}>
                      <StyledMobileData isLast={isLast}>
                        <Typography variant="titleS">{mr.title}</Typography>
                        <Typography variant="bodyL">{mr.body}</Typography>
                        {!isLast && <StyledHR />}
                      </StyledMobileData>
                    </tr>
                  );
                })}
              </tbody>
            </MobileTable>
            <MobileTable marginTop>
              <tr>
                <MobileHeader>
                  <Typography variant="titleS">Payment method</Typography>
                  <WhiteColor variant="bodyL">Bank transfer</WhiteColor>
                </MobileHeader>
              </tr>
              <tbody>
                {mobileRowsBankTransfer.map((mr, ind) => {
                  const isLast = ind === mobileRows.length - 1;
                  return (
                    <tr key={`${mr.title}-${mr.body}-row`}>
                      <StyledMobileData isLast={isLast}>
                        <Typography variant="titleS">{mr.title}</Typography>
                        <Typography variant="bodyL">{mr.body}</Typography>
                        {!isLast && <StyledHR />}
                      </StyledMobileData>
                    </tr>
                  );
                })}
              </tbody>
            </MobileTable>
          </>
        ) : (
          <StyledTable>
            <thead>
              <StyledRow>
                {headers.map((header) => (
                  <StyledHeader className="header" key={`${header.props?.id}-header`}>
                    <Typography variant="titleS">{header}</Typography>
                  </StyledHeader>
                ))}
              </StyledRow>
            </thead>

            <tbody>
              {rows.map((row) => (
                <tr key={`${row.id}-table-row`}>
                  {row.values.map((value, index) => (
                    <StyledTableData key={value.key} isNotLast={index !== 0}>
                      {value.text}
                    </StyledTableData>
                  ))}
                </tr>
              ))}
            </tbody>
          </StyledTable>
        )}
      </Container>
    </MainContainer>
  );
};

export default TableComparison;
