import React from 'react';
import styled from 'styled-components';
import Typography from '@ntuctech/devex-tangram/Typography';
import Grid from '@ntuctech/devex-tangram/Grid';

import { SCREEN_SIZE } from '../../lib/mediaQuery';
import Step1 from '../icons/svg/Step1.svg';
import Step2 from '../icons/svg/Step2.svg';
import Step3 from '../icons/svg/Step3.svg';
import Step4 from '../icons/svg/Step4.svg';

const MainContainer = styled.div`
  background: #ffffff;
`;

const Container = styled.div`
  display: flex;
  max-width: 75rem;
  flex-direction: column;
  align-items: center;
  margin: 0px auto;
  padding: 4rem 3rem;
  ${SCREEN_SIZE.Below.Tablet} {
    margin-bottom: 0;
    padding: 3rem 2rem;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    margin-bottom: 0;
    padding: 2rem 1rem;
  }
`;

const Heading = styled(Typography).attrs({ variant: 'h2' })`
  text-align: center;
  margin-bottom: 4rem;
  ${SCREEN_SIZE.Below.Tablet} {
    margin-bottom: 3rem;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    margin-bottom: 2rem;
  }
`;

const StepIcon = styled.img`
  width: 5rem;
  height: 5rem;
  margin-bottom: 1rem;
`;

const StepInfo = styled.div`
  text-align: center;
  ${SCREEN_SIZE.Below.Tablet} {
    text-align: center;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    text-align: left;
    padding-left: 1rem;
    padding-right: 0.5rem;
    h5 {
      margin-bottom: 0.5rem;
    }
  }
`;
const GridItem = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.75rem;

  ${SCREEN_SIZE.Below.MobileL} {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
  }
`;

const CorporateWorks = () => {
  return (
    <>
      <MainContainer>
        <Container>
          <Heading>Corporate gifting made easy</Heading>
          <Grid container compact>
            <GridItem item s={3} xs={12}>
              <StepIcon src={Step1.src} alt="Step1" />
              <StepInfo>
                <Typography variant="h5">1. Apply for an account</Typography>
                <Typography variant="body2">
                  You’ll need a Business account to place a corporate order.
                </Typography>
              </StepInfo>
            </GridItem>
            <GridItem item s={3} xs={12}>
              <StepIcon src={Step2.src} alt="Step2" />
              <StepInfo>
                <Typography variant="h5">2. Place order</Typography>
                <Typography variant="body2">
                  Place a bulk order based on your corporate needs.
                </Typography>
              </StepInfo>
            </GridItem>

            <GridItem item s={3} xs={12}>
              <StepIcon src={Step3.src} alt="Step3" />
              <StepInfo>
                <Typography variant="h5">3. Pay and receive</Typography>
                <Typography variant="body2">
                  Pay via credit card and get your order instantly via email.
                </Typography>
              </StepInfo>
            </GridItem>
            <GridItem item s={3} xs={12}>
              <StepIcon src={Step4.src} alt="Step4" />
              <StepInfo>
                <Typography variant="h5">4. Distribute easily</Typography>
                <Typography variant="body2">
                  Access the voucher file to start sending out vouchers.
                </Typography>
              </StepInfo>
            </GridItem>
          </Grid>
        </Container>
      </MainContainer>
    </>
  );
};

export default CorporateWorks;
