import React from 'react';
import GlobalStyle from '../components/Theme/Layout/GlobalStyle';
import FeatureFlagProvider from '../components/FeatureFlagProvider';
import Layout from '../components/Theme/Layout';
import LandingPage from '../components/LandingPage';

export default function PageIndex() {
  return (
    <FeatureFlagProvider data-testid="pageindex">
      <GlobalStyle />
      <Layout>
        <LandingPage />
      </Layout>
    </FeatureFlagProvider>
  );
}
